html {
  font-size: 16px;
}
.App {
  text-align: center;
  font-family: 'Varela';
  background-color: #fff;
  margin-left:0;
}
.topStripe{
  width: 100%;
  height: 30px;
  background-color:#1C202A;
  color:#fff !important;
  position: fixed;
  top: 0px;
  z-index: 1;
  margin-bottom: 30px;
}
#main{
  margin-top: 30px;
  background-color: #1C202A;
}

.firstCol{
  margin-left:0px !important;
  margin-right:0px !important;
}
h2{
  color:#1C202A;
  padding-top:25px;
  padding-bottom:15px;
}
.header-logo-container {
  position: sticky;
  top: 0;
}
.logo{
  max-width: 100%;
}
.startSection  {
  height: 100%;
  /* margin-left:20%;
  margin-right:20%; */
}
.introCardCol{
  margin-top: 40px;
  padding-top: 10px;
  margin-bottom: 20px;
  color:#1C202A;
 
 }



 .introCard .col{
  width:100%;
  margin: 5px;
  /* margin-bottom: 30px; */
  text-align: left;
}
.introCard a {
  color: #C95561;
}
.introCard a:hover {
  color: #C95561;
}

.Employees  {
  background-color: #fff;
  height: 100%;
  padding-bottom: 50px;
  padding-top: 30px;
  padding-left:20px;
  padding-right:20px;
}
.employeeCard{
  width:100%;
  margin-bottom: 20px;
  
}
.employeeCard .card-header{
  /* color:#C95561; */
  color:#fff;
  font-weight: 500;
  background-color: #1C202A;
}
.employeeCard .card-body{
  min-height:400px;
  background-color: #f3f3f3;
}
.employeeCard .card-footer{
  /* color:#C95561; */
  color:#fff;
  background-color: #1C202A;
}
.employeeImage {
  float:right;
  border-radius: 50% !important;
}
.employeeName{
  float:left;
  text-transform: uppercase;
  
}
.employeeTitle{
  float:right;
  
}
.employeeIntro{
  text-align: left;
  margin-bottom: 10px;
}
.employeeDescription{
  text-align: left;
  margin-top: 30px;
}


.Services  {
  
  padding-top: 30px;
  height: 100%;
  padding-left:10%;
  padding-right:10%;
  padding-bottom: 30px;
  border:0;

}
.Services h2{
  color:#fff;
}
.serviceCard{
  width:100%;
  margin-bottom: 20px;
  background-color:#1C202A;
  
}
.serviceCard .card{
  border:none;
  text-align: left;
  
}
.serviceCard img {
  width:50px;
  float:left;
}
#Contact{
  color:#1C202A;
  padding-bottom: 140px;
  padding-top:10%;
  
}

.Contacts .col{
 background-color: rgb(0, 4, 6 , .6) ;
 color:#C95561 !important;
 border: none;
}
.Contacts .row{
  background-color: rgb(0, 4, 6 , .4) ;
  color:#C95561 !important;
  border: none;
 }
.Contacts h2{
  /* color:#C95561; */
  color:#C95561 !important;
  font-size: 1.6rem;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  margin-bottom: 10px;
   
}


.serviceCard .card-header{
  background-color: #282828;
 color: #FFF;
 font-weight: 500;
 height:70px;
 font-size: 1.4rem;
 border-color:#C95561 ;
}
.serviceCard .card-body{
  background-color: #282828;
 color: #FFF;
 min-height: 300px;
}



/**/
/*Strip the ul of padding and list styling*/
ul {
  list-style-type:none;
  padding-inline-start: 0;
}
/*Create a horizontal list with spacing*/
li {
  display:inline-block;
  margin-right: 1px;
}


/* Button */

.btn{
  
  border:none !important;
  transition:none !important;
}
.menuBtn{
  background-color: transparent !important;
  color: #fff!important;
  border:none;
  border-radius: none;
  text-decoration: underline !important;
  
}
.menuBtn:active{
  background-color: transparent !important;
  color: #fff!important;
  border:none;
  text-decoration: underline !important;
}
.btn-outline-light {
  background-color: transparent !important;
  color: #fff!important;
  border:none;
  text-decoration: underline !important;
}

.employeeCard .card{
  text-align: center;
}


@media only screen and (max-width: 991px) and (min-width: 200px){
  .menuBtn{
    font-size: 0.8rem !important;
    
  }
  .introHeader{
    font-size: 1.6rem;
    margin-top: 75px;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    background-color: rgb(0, 4, 6 , .1) ;
  }
  .introCardCol{
    margin-top:0px;
    
   }
   .introIntro{
    text-align: left;
    margin-right: 40px;
    background-color: rgb(0, 4, 6 , .6) ;
    color: #fff;
    margin-left: 10px;
    margin-bottom: 40px;
    padding:30px;

  }
  .introIntro span{
    font-size: 1.2rem;
    padding-right: 60px;
    /* padding-left: 30px; */
    text-align: left;
    
    
  }
  .introDescription{
    font-size: 1.2rem;
    padding:30px;
    text-align: left;
    margin-left: 10px;
    background-color: rgb(0, 4, 6 , .6) ;
    color: #fff;
  }
  
  .employeeImage {
    height: 75px; 
    width: 75px;
    max-width: 75px;
    max-height: 75px;
    margin:0px;
    /* display:none; */
  }
  .employeeName{
    font-weight: 100;
    font-size: 0.9rem;
  }
  .employeeTitle{
    font-weight: 100;
    font-size: 0.9rem;
    display: none;
  }
  .employeeIntro{
    font-size:0.9rem;
  }
  .employeeDescription{
    font-size:0.9rem;
  }
 }
 
 @media only screen and (max-width:3000px)  and (min-width: 991px){
  #main{
    margin-top: 40px;
    background-color: #1C202A;
  }
  h2{
    font-size: 3.0rem !important;
    padding-top:50px;
    padding-bottom:50px;
  }
  .topStripe{
    min-height: 40px;
    
  }
  .topStripe button {
    font-size: 1.2rem;
    
  }
  .introHeader{
    font-size: 3rem;
    margin-top: 10px;
    margin-bottom: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: rgb(0, 4, 6 , .2) ;
  }
  .introIntro{
    text-align: left;
    margin-right: 40px;
    background-color: rgb(0, 4, 6 , .6) ;
    color: #fff;
    padding: 20px;
  }
  .introIntro span{
    font-size: 1.4rem;
    /* padding-right: 60px; */
    /* padding-left: 30px; */
    text-align: left;
  }
  .introDescription{
    font-size: 1.4rem;
    text-align: left;
    margin-left: 40px;
    background-color: rgb(0, 4, 6 , .6) ;
    color: #fff;
    padding: 20px;

  }

  .serviceCard .card-body {
    font-size: 1.2rem;
    min-height: 400px;
}
.serviceCard .card-header {
  font-size: 1.5rem;
  min-height:60px !important;
}

  .employeeCard .card-body{
    min-height:600px;
    font-size:1.4rem;
   }
   .employeeCard .card-footer{
    font-size: 1.5rem;
  }
  .employeeImage {
    height: 150px; 
    width: 150px;
    max-width: 150px;
    max-height: 150px;
    margin:0px;
  }
  .employeeName{
    font-size: 1.4rem;
    font-weight: 500;
  }
  .employeeTitle{
    font-weight: 500;
    font-size: 1.2rem;
  }
  /* .employeeIntro{
    font-size:1.4rem;
  }
  .employeeDescription{
    font-size:1.4rem;
  } */
 }
 @media only screen and (max-width:13000px)  and (min-width: 2500px){
 
      .introHeader{
        font-size: 6.0rem;
        margin-top: 10px;
        margin-bottom: 40px;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 40px;
        padding-right: 40px;
        background-color: rgb(0, 4, 6 , .2) ;
      }
      .introIntro span{
        font-size: 3.0rem;
        margin-right: 40px;
        color: #fff;
      }
      .introDescription{
        font-size: 3.0rem;
        margin-left: 40px;
        color: #fff;
      }
      .employeeCard .card-footer{
        font-size: 2.5rem;
      }
      .employeeCard .card-body{
       min-height:750px;
       font-size: 2.0rem;
      }
      .employeeName{
        font-size: 2.5rem;
      }
      .employeeTitle{
        font-size: 2.1rem;
    }
      .employeeIntro{
        font-size: 2rem;
      }
      .employeeDescription{
        font-size: 2rem;
      }
      .serviceCard .card-body {
        font-size: 2rem;
        min-height: 500px;
    }
    .serviceCard .card-header {
      font-size: 2.5rem;
      min-height:90px !important;
  }
    .topStripe {
      height: 60px;
      
    }
    .topStripe button {
      font-size: 2.0rem;
      
    }
    #main {
      margin-top: 60px;
      
    }
    .logoSection img{
      width: 600px;
    }
    h2{
      font-size: 5.0rem !important;
      padding-top:50px;
      padding-bottom:50px;
    }
    .Contacts{
      font-size: 2.2rem;
    }
    
 }

 

